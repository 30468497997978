import React from "react"
import Uninterrupted from "../images/logo.uninterrupted.dark.svg"
import Bell from "../images/logo.bell.svg"
import Tsn from "../images/logo.tsn.svg"
import { Link } from "gatsby"
import Social from "../components/social"

export default class Footer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <>
                <div className="o-wrapper o-content o-container u-text-center o-content-container">
                    <h4>{this.props.global.partners}</h4>
                    <div className="o-child-spacer u-flex-center">
                        <Link className="c-logo" to="https://www.bellmedia.ca/" target="_blank" rel="noreferrer">
                            <img src={Bell} alt="Bell" />
                        </Link>
                        <Link className="c-logo" to="https://www.tsn.ca/" target="_blank" rel="noreferrer">
                            <img src={Tsn} alt="TSN" />
                        </Link>
                    </div>
                </div>
                <footer className="c-footer o-content u-text-center">
                    <div className="o-wrapper o-container u-flex-center u-flex-wrap">
                        <div className="c-footer__logo">
                            <Link className="c-logo u-center" to="/">
                                <img src={Uninterrupted} alt={this.props.global.title} />
                            </Link>
                        </div>
                        <Social type="footer"></Social>
                        <div className="u-text-right">
                            {this.props.global.footerNavigation.map((item, index) =>
                                <>
                                    <Link key={index} className="c-footer__link" to={item.link}>{item.title}</Link>
                                    {index < this.props.global.footerNavigation.length - 1 ? " | " : null}
                                </>
                            )}
                        </div>
                    </div>
                </footer>
            </>
        );
    }
};