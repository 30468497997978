import React from "react"
import Uninterrupted from "../images/logo.uninterrupted.svg"
import { Link } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import Social from "../components/social"

export default class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hamburgerOpen: false
        };
        this.toggleHamburger = this.toggleHamburger.bind(this);
    }

    toggleHamburger() {
        this.setState({ hamburgerOpen: !this.state.hamburgerOpen });
    }

    render() {
        return (
            <>
                <header className="c-header o-content-container o-wrapper o-container">
                    <div className="c-header__top u-flex-center">
                        <div className="o-child-spacer o-child-spacer--small u-flex-grow">
                            <Link className="c-logo c-logo--small u-hide u-hide--desktop" to="/">
                                <img src={Uninterrupted} alt={this.props.global.title} />
                            </Link>
                            {/* <span className="u-hide u-hide--mobile"><FontAwesomeIcon icon={faEnvelope} /></span>
                            <Link className="u-hide u-hide--mobile" to={this.props.newsletter.url}>{this.props.newsletter.title}</Link> */}
                        </div>
                        <div role="button" className={`c-header__hamburger ${this.state.hamburgerOpen ? 'c-header__hamburger--open' : ''}`} onClick={this.toggleHamburger} onKeyPress={this.toggleHamburger} tabIndex="0">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    <Link className="c-logo u-center u-hide u-hide--mobile" to="/">
                        <img src={Uninterrupted} alt={this.props.global.title} />
                    </Link>
                </header>
                <nav className={`c-navigation o-wrapper o-container ${this.state.hamburgerOpen ? 'c-navigation--open' : ''}`}>
                    <ul className="c-navigation__items o-child-spacer">
                        {/* <li className="c-navigation__item o-child-spacer o-child-spacer--small u-hide u-hide--desktop">
                            <span><FontAwesomeIcon icon={faEnvelope} /></span>
                            <Link to={this.props.newsletter.url}>{this.props.newsletter.title}</Link>
                        </li> */}
                        {this.props.global.navigation.map((item, index) =>
                            <li key={index} className="c-navigation__item">
                                <Link to={item.link} activeClassName="c-navigation__link--active" className="c-navigation__link">{item.title}</Link>
                            </li>
                        )}
                        <li>
                            <Social type="header"></Social>
                        </li>
                    </ul>
                </nav>
            </>
        );
    }
};